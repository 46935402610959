import {HStack, IconButton, Text, VStack} from '@chakra-ui/react';
import React from 'react';
import {NextChakraLink} from './next-chakra-link';
import {SOCIAL_LINKS} from '../lib/consts';


export const Footer = () => {
    return <VStack spacing={'40px'} p={'20px'} w={'100vw'} bg={'gray.900'} minHeight={'100px'} as={'footer'}>
        <Text textAlign={'center'} color={'gray.400'}>*Alle angegebenen Preise sind Endpreise zzgl. <NextChakraLink
            color={'gray.100'} href={'/versandkosten'}>Versandkosten</NextChakraLink>. Aufgrund des
            Kleinunternehmerstatus gem. § 19 UStG erheben wir keine Umsatzsteuer und weisen diese daher auch nicht aus.</Text>

        <HStack spacing={'20px'} justify={'center'}>
            <NextChakraLink href={'/agbs'}>AGBs</NextChakraLink>
            <NextChakraLink href={'/impressum'}>Impressum</NextChakraLink>
            <NextChakraLink href={'/datenschutz'}>Datenschutzerklärung</NextChakraLink>
        </HStack>

        <HStack spacing={'20px'} justify={'center'}>

            {SOCIAL_LINKS.map(socialLink => <IconButton
                key={socialLink.name}
                as={'a'} href={socialLink.url} target={'_blank'}
                variant="ghost"
                colorScheme="cta"
                aria-label="socialLink.ariaLabel"
                fontSize="20px"
                icon={<socialLink.icon/>}
            />)}
        </HStack>

    </VStack>
};
