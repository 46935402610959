import {ChakraProvider, extendTheme, ThemeOverride} from '@chakra-ui/react';
import {AppProps} from 'next/app';
import React from 'react';
import 'focus-visible';
import '@fontsource/karla/400.css'
import '@fontsource/karla/700.css'
import '@fontsource/karla/800.css'
import {StoreLayout} from '../components/store-layout';
import {ShoppingCartProvider} from '../utils/shopping-cart';
import Script from 'next/script'


export const chakraConfig: ThemeOverride = {
    useSystemColorMode: false,
    initialColorMode: 'dark',
    colors: {
        gray: {
            50: '#f2f2f2',
            100: '#d9d9d9',
            200: '#bfbfbf',
            300: '#a6a6a6',
            400: '#8c8c8c',
            500: '#737373',
            600: '#595959',
            700: '#404040',
            800: '#212121',
            900: '#0d0d0d',
        },
        cta: {
            50: '#ffffff',
            100: '#ffffff',
            200: '#ffffff',
            300: '#efefef',
            400: '#c6c6c6',
            500: '#d7d7d7',
            600: '#b0b0b0',
            700: '#b7b7b7',
            800: '#a5a5a5',
            900: '#a2a2a2',
        },
    },
    fonts: {
        body: '\'Karla\', Arial, sans-serif',
        heading: '\'Karla\', Arial, sans-serif',
    },
    styles: {
        global: {
            body: {
                bg: 'black',
            },
        },
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: '0',
            },
        },
    },
}

export const customTheme = extendTheme(chakraConfig)

function MyApp({Component, pageProps}: AppProps) {

    if (typeof window !== 'undefined') {
        //simple analytics events https://docs.simpleanalytics.com/events
        // @ts-ignore
        window.sa_event = window.sa_event || function () {
            var a = [].slice.call(arguments);
            // @ts-ignore
            window.sa_event.q ? window.sa_event.q.push(a) : window.sa_event.q = [a]
        };
    }
    return (
        <ChakraProvider theme={customTheme}>
            {process.env.NODE_ENV === 'production' && <>
                <Script src="https://static.cloudflareinsights.com/beacon.min.js"
                        data-cf-beacon='{"token": "ffb5b735bba1416b89044bd199a9d4dd"}'/>
                <Script src="https://api.blckfng.com/latest.js"/>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <noscript><img src="https://api.blckfng.com/noscript.gif" alt=""
                               referrerPolicy="no-referrer-when-downgrade"/></noscript>

            </>
            }
            <style jsx>{`
                              // Disable outline with mouse input
                              :global(.js-focus-visible) :focus:not(.focus-visible) {
                                outline: none;
                              }

                              .non-rounded {
                                border-radius: 0 !important;
                              }

                            `}</style>
            <ShoppingCartProvider>
                <StoreLayout><Component {...pageProps}/></StoreLayout>
            </ShoppingCartProvider>
        </ChakraProvider>
    )
}

export default MyApp;
