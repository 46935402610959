import { Center, createIcon, HStack, IconButton, Image, Text, useDisclosure, VisuallyHidden, VStack } from "@chakra-ui/react";
import React from "react";
import { NextChakraLink } from "./next-chakra-link";
import { useShoppingCart } from "../utils/shopping-cart";
import Link from "next/link";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { CollectionDataFragment } from "../lib/generated";

const BagIcon = createIcon({
	displayName: "BagIcon",
	viewBox: "0 0 15 27",
	d: "M0 6H15V21H0V6ZM13 6C13 4.4087 12.4205 2.88258 11.3891 1.75736C10.3576 0.632141 8.95869 1.2014e-07 7.5 0C6.04131 -1.2014e-07 4.64236 0.632141 3.61091 1.75736C2.57946 2.88258 2 4.4087 2 6H2.9203C2.9203 4.67497 3.4028 3.40421 4.26166 2.46727C5.12052 1.53033 6.28539 1.00396 7.5 1.00396C8.71461 1.00396 9.87948 1.53033 10.7383 2.46727C11.5972 3.40421 12.0797 4.67497 12.0797 6H13Z",
});

export const HEADER_HEIGHT = "67px";
export const HEADER_HEIGHT_MOBILE = "50px";

export const Header = ({ collections }: { collections: CollectionDataFragment[] | undefined }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const headerItems = (
		<>
			{collections?.map((collection) => (
				<NextChakraLink key={collection.id} href={`/collections/${collection.handle}`}>
					{collection.title}
				</NextChakraLink>
			))}
			<NextChakraLink px={"1"} color={"gray.400"} href={`/about-us`}>
				{"ABOUT US"}
			</NextChakraLink>
			<NextChakraLink px={"1"} color={"gray.400"} href={`/nachhaltigkeit`}>
				{"NACHHALTIGKEIT"}
			</NextChakraLink>
		</>
	);

	const { cart } = useShoppingCart();
	return (
		<VStack zIndex={"10"} backgroundColor={"black"} position={"fixed"} top={"0"} py={"5px"} as={"header"} spacing={0}>
			{/* Height is substracted by 10 because of py of header */}
			<HStack h={[`calc(${HEADER_HEIGHT_MOBILE} - 10px)`, `calc(${HEADER_HEIGHT} - 10px)`]} px={["10px", "20px"]} w={"100vw"} justify={"space-between"}>
				<Center display={["block", "block", "none"]}>
					{!isOpen ? (
						<IconButton variant={"unstyled"} aria-label={"Menü öffnen"} onClick={onOpen} icon={<HamburgerIcon w={5} h={5} />} />
					) : (
						<IconButton variant={"unstyled"} aria-label={"Menü schließen"} onClick={onClose} icon={<CloseIcon w={3} h={3} />} />
					)}
				</Center>
				<HStack spacing={"40px"}>
					<NextChakraLink href={"/"} onClick={onClose}>
						<HStack>
							<VisuallyHidden>Startseite</VisuallyHidden>
							<Image alt={"Blickfang Schriftzug weiß"} width={["130px", "150px"]} height={"auto"} src={"/images/blickfang_schriftzug.png"} title="Blickfang Logo" />
						</HStack>
					</NextChakraLink>
					<HStack spacing={"15px"} display={["none", "none", "block"]}>
						{headerItems}
					</HStack>
				</HStack>

				<HStack spacing={"20px"}>
					<Link href={"/cart"}>
						<HStack cursor={"pointer"} pl={["0", "20px"]} spacing={"0px"}>
							<IconButton h={"30px"} w={"30px"} variant={"unstyled"} aria-label={"Warenkorb"} icon={<BagIcon w={6} h={6} />} />
							{cart && <Text w={"20px"}>{cart.lines.edges.reduce((previous, current) => previous + current.node.quantity, 0)}</Text>}
						</HStack>
					</Link>
				</HStack>
			</HStack>
			<HStack mt={0} spacing={"15px"} h={isOpen ? "60px" : 0} justifyContent={"center"} overflow={"hidden"} flexWrap={"wrap"} transition={"height 0.2s"}>
				{headerItems}
			</HStack>
		</VStack>
	);
};
