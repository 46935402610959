import {CartDataFragment} from '../lib/generated';
import {addVariantToCart, createCart, deleteLineItem, getCartData, updateLineItem} from './shop_backend';
import React, {PropsWithChildren, useContext, useEffect} from 'react';
import * as Sentry from '@sentry/browser';
import useLocalStorageState from 'use-local-storage-state';


export const formatCurrencyString = (inputString: string | number) => {
    let value = Number(inputString);
    const numberFormat = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    })
    // @ts-ignore
    return numberFormat.format(value.toFixed(2))
}

//the useShoppingCart hook uses context internally so every component accesses the same hook, which prevents multiple api calls
// @ts-ignore to create context without a default value
const ShoppingCartContext = React.createContext<ReturnType<typeof useShoppingCartInternal>>(undefined);

export const ShoppingCartProvider = ({children}: PropsWithChildren<any>) => {
    return <ShoppingCartContext.Provider value={useShoppingCartInternal()}>
        {children}
    </ShoppingCartContext.Provider>
}


export function useShoppingCart() {
    return useContext(ShoppingCartContext)
}


function useShoppingCartInternal() {
    let [cart, setCart] = useLocalStorageState<CartDataFragment | null>('shopify_cart_data', {defaultValue: null});


    //gets called at most once per page load, because the hook is only created once and then served by the context provider
    useEffect(() => {
        if (cart?.id) {
            //expire cart after 14 days
            if (Date.now() - 1209600000 > Date.parse(cart.updatedAt)) {
                setCart(null);
                Sentry.setTag('shopify_cart_id', null);
                return;
            }
            Sentry.setTag('shopify_cart_id', cart.id);
            getCartData(cart.id).then(newCart => {
                newCart ? setCart(newCart) : setCart(null);
            });
        }
    }, [cart?.id, cart?.updatedAt, setCart]);

    async function addToCart(variantId: string, quantity: number = 1): Promise<void> {
        const cartData = cart?.id ? await addVariantToCart(cart.id, variantId, quantity) : await createCart(variantId, quantity);
        if (cartData) {
            setCart(cartData)
        }
    }

    async function setQuantity(lineId: string, quantity: number): Promise<void> {
        const cartData = cart?.id ? await updateLineItem(cart.id, lineId, quantity) : await createCart(lineId, quantity);
        if (cartData) {
            setCart(cartData)
        }
    }

    async function removeFromCart(lineId): Promise<void> {
        if (cart?.id) {
            const cartData = await deleteLineItem(cart.id, lineId);
            if (cartData) setCart(cartData);
        }
    }

    return {addToCart, removeFromCart, setQuantity, cart}
}

