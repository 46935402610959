import {Box, Flex} from '@chakra-ui/react';
import {Header, HEADER_HEIGHT, HEADER_HEIGHT_MOBILE} from './header';
import React, {PropsWithChildren} from 'react';
import {Footer} from './footer';
import {CollectionDataFragment} from '../lib/generated';

export const StoreLayout = ({children}: PropsWithChildren<any>) => {

    const collections: CollectionDataFragment[] = children.props.collections;

    return <Flex flexDir={'column'} minH={'100vh'} height={'100%'} overflowX={'hidden'}>
        <Header collections={collections}/>
        {/*@ts-ignore*/}
        <Box as={'main'} marginTop={[HEADER_HEIGHT_MOBILE, HEADER_HEIGHT]}
             minH={[`calc(100vh - ${HEADER_HEIGHT_MOBILE})`, `calc(100vh - ${HEADER_HEIGHT})`]}>
            {children}
        </Box>
        <Footer/>
    </Flex>

}
