import {FaInstagram, FaPinterestP, FaRegEnvelope, FaTiktok} from 'react-icons/fa';

export const ENABLE_PRODUCT_DROP = process.env.NEXT_PUBLIC_ENABLE_PRODUCT_DROP === 'true';


export const SOCIAL_LINKS = [
    {url: 'https://www.instagram.com/blickfang_de/', icon: FaInstagram, name: 'Instagram'},
    {url: 'https://www.pinterest.de/Blickfang_de', icon: FaPinterestP, name: 'Pinterest'},
    {url: 'mailto:shop@blckfng.com', icon: FaRegEnvelope, name: 'Email'},
    {url: 'https://www.tiktok.com/@blickfang_de', icon: FaTiktok, name: 'TikTok'},

];

