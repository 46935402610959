import {GraphQLClient} from 'graphql-request';
import {
    CartDataFragment,
    CollectionDataFragment,
    CollectionDataWithProductsFragment,
    getSdk,
    ProductDataFragment,
    SelectedOption,
    VariantDataFragment,
} from '../lib/generated';


const sdk = getSdk(new GraphQLClient('https://' + process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN + '/api/2022-10/graphql.json' || '', {
    headers: {
        'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN || '',
    },
}));


export const getCollections = async (): Promise<CollectionDataFragment[] | undefined> => {
    return (await sdk.getCollections()).data?.collections.edges.map(value => value.node);
}

export const getCollectionHandles = async (): Promise<string[] | undefined> => {
    return (await sdk.getCollectionHandles()).data?.collections.edges.map(value => value.node.handle)
}

export const getCollectionByHandle = async (handle: string): Promise<CollectionDataWithProductsFragment | null | undefined> => {
    return (await sdk.getCollectionByHandle({handle})).data?.collection;
}

export const getProducts = async (): Promise<ProductDataFragment[] | undefined> => {
    return (await sdk.getProducts()).data?.products.edges.map(value => value.node);
}

export const getProductHandles = async (): Promise<string[] | undefined> => {
    return (await sdk.getProductHandles()).data?.products.edges.map(value => value.node.handle)
}

export const getProductByHandle = async (handle: string): Promise<ProductDataFragment | null | undefined> => {
    return (await sdk.getProductByHandle({handle})).data?.product;
}

export const getProductById = async (id: string): Promise<ProductDataFragment | null | undefined> => {
    return (await sdk.getProductById({id})).data?.product;
}


export const getCartData = async (cartId: string): Promise<CartDataFragment | null | undefined> => {
    return (await sdk.getCartData({cartId}))?.data?.cart;

}

export const addVariantToCart = async (cartId: string, variantId: string, quantity: number): Promise<CartDataFragment | null | undefined> => {
    return (await sdk.addVariantToCart({cartId, variantId, quantity})).data?.cartLinesAdd?.cart;
}

export const updateLineItem = async (cartId: string, lineId: string, newQuantity: number): Promise<CartDataFragment | null | undefined> => {
    return (await sdk.updateLineItem({cartId, lineId, newQuantity})).data?.cartLinesUpdate?.cart;
}

export const deleteLineItem = async (cartId: string, lineId: string): Promise<CartDataFragment | null | undefined> => {
    return (await sdk.deleteLineItem({cartId, lineId})).data?.cartLinesRemove?.cart;
}

export const createCart = async (variantId: string, quantity: number): Promise<CartDataFragment | null | undefined> => {
    return (await sdk.createCart({variantId, quantity})).data?.cartCreate?.cart;
}

export const findVariantByOptions = (variants: VariantDataFragment[], selectedOption: SelectedOption[]): VariantDataFragment => {
    //Filters variants by options until only the matching one remains.
    for (let option of selectedOption) {
        variants = variants.filter(variant => variant.selectedOptions.find(optionValue => optionValue.name === option.name)?.value === option.value)
    }

    return variants[0];
}

export const findVariantsByOptions = (variants: VariantDataFragment[], selectedOption: SelectedOption[]): VariantDataFragment[] => {
    //Filters variants by options until only the matching one remains.
    for (let option of selectedOption) {
        variants = variants.filter(variant => variant.selectedOptions.find(optionValue => optionValue.name === option.name)?.value === option.value)
    }
    return variants;
}

